import React, { Component } from "react";
import {
  Grid,
  Jumbotron,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import MapContainer from "./MapContainer";
import Navbar from "./CustomNav";
import Footer from "./Footer";

const emailjs = require("emailjs-com");
class Contact extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      message: "",
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async handleSubmit(e) {
    //e.preventDefault();

    const { name, email, message, isLoading } = this.state;
    this.setState({ isLoading: true });
    await this.sendMail({ name, email, message });
    alert("Email erfolgreich versandt!");
    this.setState({ isLoading: false });

    // const form = await axios.post('/api/form', {
    // 	name,
    // 	email,
    // 	message
    // });
  }

  async sendMail({ name, email, message }) {
    try {
      const result = emailjs.send(
        "default_service",
        "template_egIM8r4B",
        { name, email, message },
        "user_UNH0pgi4UtmHSqP7FVXvi"
      );
      console.log("success", result);
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <Image src="assets/banner.jpg" className="header-image" />
        <Navbar />
        <Grid>
          <h2>Kontakt</h2>
          <br />
          <Row>
            <Col xs={12} sm={8} style={{ paddingBottom: "35em" }}>
              {/*<Jumbotron>
								<p>Hier können sie mir eine Email senden</p>
								<Form onSubmit={this.handleSubmit}>
									<FormGroup>
										<ControlLabel htmlFor="name">Name:</ControlLabel>
										<FormControl type="text" name="name" onChange={this.handleChange} />
										<ControlLabel htmlFor="email">Email:</ControlLabel>
										<FormControl type="email" name="email" onChange={this.handleChange} />
										<br />
										<ControlLabel htmlFor="message">Nachricht:</ControlLabel>
										<FormControl
											componentClass="textarea"
											name="message"
											onChange={this.handleChange}
										/>
									</FormGroup>
									<Button disabled={isLoading} type="submit">
										Senden
										</Button>
								</Form> 
								
							</Jumbotron>*/}

              <MapContainer />
            </Col>
            <Col xs={4} xsOffset={4} smOffset={0}>
              <Image src="assets/kontakt.jpg" className="profile-pic" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8}>
              <p>
                Sollten Sie mit dem Auto kommen, beachten Sie bitte das der
                gesamte 16. Bezirk Kurzparkzone ist.
                <br /> Es ist jedoch möglich in der öffentlichen Spar/Baumarkt
                Fetter Tiefgarage für 1 1/2 h zu parken.
                <br /> Die Praxis ist über die U3 Station Ottakring und
                anschließend in 4 Minuten mit der Autobuslinie 46A
                <br /> (Station Wilhelminenstraße/Sandleitengasse) gut zu
                erreichen.
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>Tel:</span> 0680 –
                  2012366
                </li>
                <br />
                <li>
                  <span style={{ fontWeight: "bold" }}>Mail:</span>{" "}
                  <a href="mailto:schulzhaizinger@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} color="#f9bf29" />{" "}
                    schulzhaizinger@gmail.com
                  </a>
                </li>
                <br />
                <li>
                  <span style={{ fontWeight: "bold" }}>Praxisadressen:</span>{" "}
                  <br /> Psychotherapeutische Praxis <br /> Wilhelminenstraße
                  93A/15/14 <br />
                  1160 Wien
                </li>
                <br />
                <li>
                  (begrenztes Stundenkontingent = Albrechtsstraße 98/10, 3400
                  Klosterneuburg)
                </li>
              </ul>
            </Col>
          </Row>
        </Grid>

        <Footer style={{ marginTop: "1em" }} />
      </div>
    );
  }
}

export default Contact;
