import React, { Component } from 'react';
import { Jumbotron, Grid, Col, Row, Image } from 'react-bootstrap';
import './Teens.css';
import Navbar from './CustomNav';

export default class Teens extends Component {
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Psychotherapie für Jugendliche</h2>
					<br />
					<Row>
						<Col xs={12} sm={4}>
							<Image src="assets/kinder_2.jpg" className="profile-pic" />
							<Image src="assets/kinder_4.jpg" className="profile-pic" />
						</Col>
						<Col xs={12} sm={8}>
							<Jumbotron>
								<h4>Häufige Themen in der Psychotherapie mit Jugendlichen sind</h4>
								<br />
								<ul style={{ listStyleType: 'disc' }}>
									<li>Konflikte mit dem Umfeld</li>
									<br />
									<li>Aggression und Gewalt</li>
									<br />
									<li>Lernblockaden, schulische Probleme</li>
									<br />
									<li>Mobbing</li>
									<br />
									<li>Zukunftsängste</li>
									<br />
									<li>Rückzug, Antriebslosigkeit, Depression</li>
									<br />
									<li>Suchtproblematik</li>
									<br />
									<li>Schwierigkeiten in der Ablösung</li>
									<br />
									<li>Unsicherheiten, Ängste</li>
									<br />
									<li>Persönlichkeitsentwicklung, Sinnfindung</li>
									<br />
									<li>Probleme mit dem eigenen Körper</li>
									<br />
									<li>Essstörungen</li>
									<br />
									<li>Sexualität und Beziehungsformen</li>
								</ul>
							</Jumbotron>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}
