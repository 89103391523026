import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Psychotherapy from './components/Psychotherapy';
import Children from './components/Children';
import Advice from './components/Advice';
import Terms from './components/Terms';
import Contact from './components/Contact';
import Navbar from './components/CustomNav';
import Teens from './components/Teens';
import Parents from './components/Parents';
import Datenschutz from './components/Datenschutz';

class App extends Component {
	render() {
		return (
			<Router>
				<div>
					{/* <Navbar />   */}
					<Route exact path="/" component={Home} />
					<Route exact path="/psychotherapie" component={Psychotherapy} />
					<Route exact path="/kinder" component={Children} />
					<Route exact path="/jugendliche" component={Teens} />
					<Route exact path="/eltern" component={Parents} />
					<Route exact path="/beratung" component={Advice} />
					<Route exact path="/rahmenbedingungen" component={Terms} />
					<Route exact path="/uebermich" component={About} />
					<Route exact path="/kontakt" component={Contact} />
					<Route exact path="/datenschutz" component={Datenschutz} />
				</div>
			</Router>
		);
	}
}

export default App;
