import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CustomNav.css';

class CustomNav extends Component {
	render() {
		return (
			<div>
				{/* <Image src="assets/header_test.JPG" className="header-image" /> */}
				<Navbar default collapsedonselect="true">
					<Navbar.Header>
						<Navbar.Brand>
							<Link to="/">
								Mag.<sup>a</sup> Christina Schulz-Haizinger

							</Link>
						</Navbar.Brand>
						<Navbar.Toggle />
					</Navbar.Header>
					<Navbar.Collapse>
						<Nav>
							<NavItem eventKey={1} componentClass={Link} href="/psychotherapie" to="/psychotherapie">
								Psychotherapie
							</NavItem>
							<NavDropdown id="dropdown" eventKey={2} title="Psychotherapie für Kinder & Jugendliche">
								<MenuItem eventKey={2.1} componentClass={Link} href="/kinder" to="/kinder">
									Psychotherapie für Kinder (Spieltherapie)
								</MenuItem>
								<MenuItem eventKey={2.2} componentClass={Link} href="/jugendliche" to="/jugendliche">
									Psychotherapie für Jugendliche
								</MenuItem>
								<MenuItem eventKey={2.3} componentClass={Link} href="/eltern" to="/eltern">
									Elternberatung
								</MenuItem>
							</NavDropdown>

							<NavItem eventKey={4} componentClass={Link} href="/beratung" to="/beratung">
								Beratung & Supervision
							</NavItem>
							<NavItem
								eventKey={5}
								componentClass={Link}
								href="/rahmenbedingungen"
								to="/rahmenbedingungen"
							>
								Rahmenbedingungen
							</NavItem>
							<NavItem eventKey={6} componentClass={Link} href="/uebermich" to="/uebermich">
								Über mich
							</NavItem>
							<NavItem eventKey={7} componentClass={Link} href="/kontakt" to="/kontakt">
								Kontakt
							</NavItem>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default CustomNav;
