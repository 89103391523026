import React, { Component } from 'react';
import { Grid, Col, Row, Image, Jumbotron } from 'react-bootstrap';
import './Datenschutz.css';
import Navbar from './CustomNav';

export default class Datenschutz extends Component {
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<Jumbotron>
						<h2>Datenschutz</h2>

						<p>
							Die Betreiberin dieser Seiten nimmt den Schutz Ihrer persönlichen Daten sehr ernst.
							Ich behandle Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
							Datenschutzvorschriften sowie dieser Datenschutzerklärung. Die Nutzung dieser Webseite ist
							in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf meiner Seiten
							personenbezogene Daten (beispielsweise Name oder E-Mail-Adressen) erhoben werden, erfolgt
							dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
							ausdrückliche Zustimmung nicht an Dritte weitergegeben. Ich weise darauf hin, dass die
							Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
							aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
							möglich.
						</p>
						<br />
						<h4>Kontaktformular</h4>
						<p>
							Wenn Sie mir per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
							Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
							Anfrage und für den Fall von Anschlussfragen bei mir gespeichert. Diese Angaben werde nicht
							an Dritte weitergegeben und nach einem Jahr gelöscht.
						</p>
						<br />
						<h4>SSL-Verschlüsselung</h4>
						<p>
							Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
							Inhalte, wie zum Beispiel der Anfragen, die Sie an mich als Seitenbetreiber senden, eine
							SSL-Verschlüsselung. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie
							übermitteln, nicht von Dritten mitgelesen werden.
						</p>
						<br />
						<h4>Google Maps</h4>
						<p>
							Ich binde die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC, 1600
							Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
							https://www.google.com/policies/privacy/ , Opt- Out:
							https://adssettings.google.com/authenticated .
						</p>
						<br />
						<h4>Ihre Rechte</h4>
						<p>
							Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
							Widerspruch und Datenübertragbarkeit zu. Dafür wenden Sie sich an mich. Für die
							Datenverarbeitung verantwortlich: Christina Schulz-Haizinger; Email:
							schulzhaizinger@gmail.com, Tel: +43680-2012366
						</p>
						<br />
						<h4>Widerspruch Werbe-Mails</h4>
						<p>
							Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
							Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
							hiermit widersprochen. Ich behalte mir rechtliche Schritte im Falle der unverlangten
							Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
						</p>
					</Jumbotron>
				</Grid>
			</div>
		);
	}
}
