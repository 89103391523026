import React, { Component } from 'react';
import { Jumbotron, Grid, Row, Col, Image } from 'react-bootstrap';
import Navbar from './CustomNav';
import './Home.css';

export default class Home extends Component {
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<div>
					<Grid>
						<Row>
						</Row>
						<Row style={{ marginTop: '2em', marginBottom: '2em' }}>

							<Col xs={12} sm={4}>
								<h1>PSYCHOTHERAPIE</h1>
								<h3>BERATUNG & SUPERVISION</h3>
								<h4>
									Mag.<sup>a</sup> Christina Schulz-Haizinger
								</h4>
								<Jumbotron>
									<h4>HERZLICH WILLKOMMEN!</h4>
									<p>
										Auf diesen Seiten möchte ich Ihnen einen Einblick
										über mein Angebot und meine Arbeitsweise geben.
Ich freue mich über eine Kontaktaufnahme!</p>
								</Jumbotron>

							</Col>
							<Col xs={12} sm={7} smOffset={1}>
								<Image src="assets/lachend.JPG" className="profile-pic" />
							</Col>
						</Row>
					</Grid>
					<Grid fluid={true}>
						<Row style={{ background: '#c7fafc', padding: '1em' }} >
							<Col xs={12} sm={12} >

								<h3 className="quote">„The curious paradox is that when I accept myself just as I am,<br /> then I can change.“ Carl R. Rogers</h3>


							</Col>
						</Row>
					</Grid>
				</div>
			</div>
		);
	}
}
