import React, { Component } from "react";
import { Grid, Col, Row, Image, Jumbotron } from "react-bootstrap";
import "./About.css";
import Navbar from "./CustomNav";

class About extends Component {
  state = {};
  render() {
    return (
      <div>
        <Image src="assets/banner.jpg" className="header-image" />
        <Navbar />
        <Grid>
          <h2>Zu meiner Person</h2>
          <br />
          <Row>
            <Col xs={12} sm={8} className="main-section">
              <Jumbotron>
                <h4>
                  Psychosoziale & Therapeutische Praxis und Berufserfahrung
                </h4>
                <ul style={{ listStyleType: "disc" }}>
                  <li>Psychotherapeutin in freier Praxis</li>
                  <br />
                  <li>
                    Zertifizierte Kinder- und Jugendpsychotherapeutin (gemäß
                    III.C.1 des Bundesministerium für Gesundheit)
                  </li>
                  <br />
                  <li>
                    Familien-, Eltern- bzw. Erziehungsberaterin nach §95 und
                    §107 (Bundesministerium für Frauen, Familien und Jugend)
                  </li>
                  <br />
                  <li>
                    2014-2016 Psychotherapeutin in Ausbildung unter Supervision
                    im Familienzentrum der Caritas Wien
                  </li>
                  <br />
                  <li>
                    2016 Klinisches Praktikum am SMZ-Ost an der Psychiatrischen
                    Subakut-Station
                  </li>
                  <br />
                  <li>
                    Seit 2011 in der Behindertenhilfe St. Martin, Klosterneuburg
                  </li>
                  <br />
                  <li>
                    Mitarbeit in verschiedenen „Alternativschulen“
                    (Lernwerkstatt Döbling, Galemo, Lernwerkstatt Ottakring)
                  </li>
                  <br />
                  <li>Flugbegleiterin</li>
                  <br />
                  <li>Anna Freud Kindergarten, 1180 Wien</li>
                  <br />
                  <li>
                    Sozialpädagogische-integrative Kinder-und
                    Jugendwohngemeinschaft Clara Fey, 1190 Wien
                  </li>
                </ul>
                <br />
                <h4>Bildungsweg</h4>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    2011-2018 Ausbildung zur personzentrierten Psychotherapeutin
                    (Forum)
                  </li>
                  <br />
                  <li>
                    Weiterbildung zur Kinder- und Jugendpsychotherapeutin
                    (VRP/Forum)
                  </li>
                  <br />
                  <li>2008-2011 Propädeutikum APG</li>
                  <br />
                  <li>
                    1996-2004 Studium Erziehungswissenschaften/Sonder- und
                    Heilpädagogik an der Universität Wien und Florenz
                  </li>
                  <br />
                  <li>Kindergarten- und Montessoripädagogin</li>
                </ul>
                <br />
                <h4>Persönliches</h4>
                <ul style={{ listStyleType: "disc" }}>
                  <li>3 Kinder, verheiratet</li>
                </ul>
              </Jumbotron>
            </Col>

            <Col xs={12} sm={4} className="sidebar-section">
              <Image src="assets/ueber_mich.jpg" className="profile-pic" />
              <h4 className="header-text">
                Mag.
                <sup>a</sup> Christina Schulz-Haizinger
              </h4>
              <h4>Psychotherapiepraxis Wilhelminenstraße</h4>
              <p className="centered">
                Wilhelminenstraße 93A/15/14 <br /> 1160, Wien
              </p>
              <p className="centered">0680 2012366</p>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default About;
