import React, { Component } from 'react';
import { Grid, Col, Row, Image, Jumbotron } from 'react-bootstrap';
import './Parents.css';
import Navbar from './CustomNav';

export default class Parents extends Component {
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Elternberatung</h2>
					<br />
					<Jumbotron>
						<Row>
							<Col xs={12} sm={12}>
								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Es besteht die Möglichkeit einer Unterstützung in Form einer Elternberatung.
									</li>
									<br />
									<li>
										In dieser können Themen wie die eigenen Erwartungen, Vorstellungen und Ansprüche
										bezüglich einer „gelungenen Erziehung“ geklärt und eigene Leitlinien für das
										familiäre Zusammenleben klarer erkannt, definiert, verbalisiert und letztendlich
										nach außen vertreten werden.
									</li>
									<br />
									<li>
										Je klarer Sie sich über Ihre Haltungen, Bedürfnisse und Ihre Grenzen sind, umso
										leichter kann es Ihnen – im Trubel des Familienalltags – fallen, Orientierung zu
										behalten, Position zu beziehen und Entscheidungen zu treffen.
									</li>
									<br />
									<li>
										Ich biete auch Beratungen nach §95 und §107 an und bin zertifizierte Familien-,
										Eltern- und Erziehungsberaterin (Bundesministerium für Frauen, Familie und
										Jugend).
									</li>
								</ul>

								<Image src="assets/beratung_2.jpg" className="pig-pic" />
							</Col>
						</Row>
					</Jumbotron>
				</Grid>
			</div>
		);
	}
}
