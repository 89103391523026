import React, { Component } from 'react';
import { Jumbotron, Grid, Col, Row, Image } from 'react-bootstrap';
import './Terms.css';
import Navbar from './CustomNav';

class Terms extends Component {
	state = {};
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Rahmenbedingungen</h2>
					<br />
					<Jumbotron>
						<Row>
							<Col xs={12} sm={8}>
								<h5 style={{ fontWeight: 'bold' }}>Erstgespräch</h5>
								<p>
									Das Erstgespräch dient dem gegenseitigen Kennenlernen und dem Klären von Fragen, die
									für eine Zusammenarbeit wichtig sind.
								</p>
								<h5 style={{ fontWeight: 'bold' }}>Kosten</h5>
								<p>Eine Teilrefundierung der Kosten durch die Krankenkasse ist möglich.</p>
								<h5 style={{ fontWeight: 'bold' }}>Therapiedauer und Therapiefrequenz</h5>
								<p>
									Eine Einzeltherapie Einheit dauert 50 Minuten. Üblicherweise findet die Sitzung
									einmal pro Woche statt. Entsprechend dem Anliegen ist auch eine andere Frequenz
									möglich. Paar- und Familientherapie Einheiten dauern üblicherweise 75 Minuten.
								</p>
							</Col>
							<Col xs={12} sm={4}>
								<Image src="assets/rahmen_1.jpg" className="profile-pic" />
							</Col>
						</Row>
						<br />
						<Row>
							<Col xs={12} sm={6}>
								<Image src="assets/rahmen_2.jpg" className="profile-pic" />
							</Col>
							<Col xs={12} sm={6}>
								<h5 style={{ fontWeight: 'bold' }}>Verschwiegenheitspflicht</h5>
								<p>
									Sämtliche Inhalte, die in psychotherapeutischen Sitzungen besprochen werden,
									unterliegen einer Verschwiegenheitspflicht. Diese ist im österreichischen
									Psychotherapiegesetz verankert (vgl. Psychotherapiegesetz §15).
								</p>
								<h5 style={{ fontWeight: 'bold' }}>Absageregelung</h5>
								<p>
									Wenn Sie einen vereinbarten Termin nicht wahrnehmen können, bitte ich um eine
									zeitgerechte Absage spätestens 24 Stunden vor unserer Sitzung. Ansonsten muss ich
									die Einheit verrechnen. Ich bitte diesbezüglich um Ihr Verständnis.
								</p>
							</Col>
						</Row>
					</Jumbotron>
				</Grid>
			</div>
		);
	}
}

export default Terms;
