import React, { Component } from 'react';
import { Jumbotron, Grid, Col, Row, Image } from 'react-bootstrap';
import './Children.css';
import Navbar from './CustomNav';

class Children extends Component {
	state = {};
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Psychotherapie für Kinder (Spieltherapie)</h2>
					<br />
					<Row>
						<Col xs={12} sm={8}>
							<Jumbotron>
								<h3>Wie Erwachsene leiden auch Kinder und Jugendliche unter seelischen Problemen.</h3>
								<br />

								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Manches macht sich in verschlüsselter Form bemerkbar, das sich nur schwer
										verstehen und einordnen lässt.
									</li>
									<br />
									<li>
										Über das Medium Spiel wird es dem Kind möglich, seine Sicht der Welt und damit
										auch Probleme und seine emotionale Befindlichkeit darzustellen. Im
										therapeutischen Spiel mit dem Kind wird versucht, gemeinsame Lösungen zu finden,
										Erfahrungen neu zu organisieren und Veränderung anzuregen.
									</li>
									<br />
									<li>
										Wie eine Art Übersetzerin versuche ich dem Kind und seinen Eltern die Bedeutung
										der belastenden Symptome zugänglich zu machen.
									</li>
									<br />
									<li>
										Daher ist die Einbindung der Eltern bzw. der Bezugspersonen in den
										therapeutischen Prozess in Form von begleitenden Gesprächen wichtig und
										notwendig.
									</li>
								</ul>
							</Jumbotron>
							<Image src="assets/kinder_5.jpg" className="profile-pic" />
						</Col>
						<Col xs={12} sm={4}>
							<Image src="assets/kinder_1.jpg" className="profile-pic" />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

export default Children;
