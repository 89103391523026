import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<h5 className="impressumHeader">Impressum</h5>
				<Link href="/datenschutz" to="/datenschutz">
					Datenschutzrichtlinie
				</Link>

				<p className="impressum">
					Offenlegung nach § 5 Abs.1, E - Commerce - Gesetz (ECG): Seiteninhaber: Christina Schulz-Haizinger |
					Psychotherapeutin | Supervisorin | Tel. 0680-2012366 | E-Mail: schulzhaizinger@gmail.com
					<br />
					Die Inhalte dieser Website dienen zu Informationszwecken. Es wird keinerlei rechtsgründige Haftung
					für die Richtigkeit, Aktualität und Vollständigkeit der Inhalte übernommen. Alle Angaben sind ohne
					Gewähr. Ich übernehme keine Verpflichtung, die hier enthaltenen Informationen auf dem neuesten Stand
					zu halten und behalte mir das Recht vor, diese jederzeit zu ändern. Die Inhalte sind
					urheberrechtlich geschützt. Jegliche Verwendung über den Eigenbedarf hinaus bedarf der Zustimmung
					der Urheberin. Trotz sorgfältiger Kontrolle übernehme ich keine Haftung für die Inhalte externer
					Links und Verweise. Für den Inhalt der entsprechenden Seiten sind ausschließlich deren Betreiber
					verantwortlich.
					<br />
					Portraitfoto: Isabelle Orsini-Rosenberg <br /> Zuständige Aufsichtsbehörde für
					PsychotherapeutInnen: <a target="_blank" href="https://www.bmgf.gv.at">
						Bundesministerium für Gesundheit
					</a>
					<br /> Berufsrechtliche Vorschrift: Psychotherapiegesetz BGBL. Nr. 361/1990, zu finden im
					Rechtsinformationssystem des Bundeskanzleramtes
				</p>
			</div>
		);
	}
}
