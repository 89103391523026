import React, { Component } from 'react';
import { Grid, Col, Row, Image, Jumbotron } from 'react-bootstrap';
import './Advice.css';
import Navbar from './CustomNav';

class Advice extends Component {
	state = {};
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Beratung & Supervision</h2>
					<br />
					<Jumbotron>
						<Row>
							<Col xs={12} sm={6}>
								<h4>Beratung</h4>
								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Im Gegensatz zur Psychotherapie bedeutet Beratung meist die Arbeit über einen
										kürzeren Zeitraum und behandelt konkrete Fragestellungen, bei denen persönliche
										oder soziale Konflikte im Vordergrund stehen.
									</li>
									<br />
									<li>
										Ziel des Angebotes ist es, einen persönlichen Weg zur Problembewältigung zu
										finden bzw. daraus entstandene Entscheidungen bewusst umzusetzen.
									</li>
								</ul>
							</Col>
							<Col xs={12} sm={6}>
								<h4>Supervision</h4>
								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Supervision kann im Einzelgespräch oder in einer Gruppe (Arbeitsteam)
										stattfinden. Sie dient dazu die praktische Arbeit, Rollen- und
										Beziehungsdynamiken, sowie Entscheidungsprozesse zu prüfen und zu verbessern.
										Auch Falldarstellungen können Inhalt einer Supervision sein.
									</li>
									<br />
									<li>Gemeinsames Lernen, Synergien und Lösungen finden stehen im Vordergrund.</li>
								</ul>
							</Col>
						</Row>
						<Row>
							<Image src="assets/beratung.jpg" className="plant-pic" />
						</Row>
					</Jumbotron>
				</Grid>
			</div>
		);
	}
}

export default Advice;
