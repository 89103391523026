import React, { Component } from 'react';
import { Jumbotron, Grid, Col, Row, Image } from 'react-bootstrap';
import './Psychotherapy.css';
import Navbar from './CustomNav.js';

class Psychotherapy extends Component {
	state = {};
	render() {
		return (
			<div>
				<Image src="assets/banner.jpg" className="header-image" />
				<Navbar />
				<Grid>
					<h2>Psychotherapie</h2>
					<br />
					<Row>
						<Col xs={12} sm={8} className="main-section">
							<Jumbotron>
								<h3>Wann ist Psychotherapie sinnvoll?</h3>
								<br />
								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Wenn Sie in einer Krisensituation nicht mehr weiter wissen oder wenn Sie längere
										Zeit niedergeschlagen, traurig oder antriebslos sind und Sie ihre Lebensfreude
										wiederfinden wollen.
									</li>
									<br />
									<li>
										Wenn Schlafstörungen, Stress, das Gefühl der Überforderung, Ängste oder auch
										Panik Ihren Alltag beeinträchtigen.
									</li>
									<br />
									<li>
										Wenn es Ihnen schwer fällt, sich in eine neue Lebenssituation einzufinden, z.B.
										bei einer Trennung, dem Tod einer nahen Person oder einem anderen Verlust.
									</li>
									<br />
									<li>Wenn Sie das Gefühl haben, immer wieder vor denselben Konflikten zu stehen.</li>
									<br />
									<li>
										Manchmal steht auch das Interesse an einer persönlichen Weiterentwicklung im
										Vordergrund.
									</li>
								</ul>
							</Jumbotron>
							<Jumbotron>
								<h3>Psychotherapie bedeutet</h3>
								<br />
								<ul style={{ listStyleType: 'disc' }}>
									<li>
										Sich im geschützten Rahmen mit sich selbst, den eigenen Erfahrungen und
										konflikthaften Situationen auseinanderzusetzen.
									</li>
									<br />
									<li>
										Das eigene Fühlen, Denken und Handeln besser verstehen und annehmen zu lernen.
									</li>
									<br />
									<li>Eigene Ressourcen neu zu entdecken.</li>
									<br />
									<li>Neue Handlungs- und Erlebensmöglichkeiten zu entwickeln.</li>
									<br />
									<li>Konflikte durcharbeiten und austragen zu lernen.</li>
								</ul>
							</Jumbotron>
						</Col>
						<Col xs={12} sm={4} className="sidebar-section">
							<Image src="assets/psychotherapie.jpg" className="profile-pic" />
							<p className="sideText">
								Die personzentrierte Psychotherapie ist eine gesetzlich anerkannte Therapierichtung. Sie
								wurde 1940 von C.R. Rogers gegründet und seither laufend weiterentwickelt. Ihre
								Wirksamkeit ist durch wissenschaftliche Studien bestätigt.
							</p>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

export default Psychotherapy;
